<template>
  <button
    :class="{
      'flex items-center justify-center': centerContent
    }"
    type="button"
    :disabled="disabled"
    @click="handleButtonClick"
  >
    <slot :blue="blue">
      {{ text }}
    </slot>
    <loader
      :isLoading="isLoading"
      :background-color="backgroundColor"
      size="20"
    />
  </button>
</template>
<script setup lang="ts">
import { useClipboardWithMessage } from "@/hooks/clipboard";

const props = defineProps({
  text: {
    type: [String, Number, Date],
    default: ""
  },
  blue: {
    type: Boolean,
    default: false
  },
  centerContent: {
    type: Boolean,
    default: false
  },
  isLoading: {
    type: Boolean
  },
  disabled: {
    type: Boolean,
    default: false
  },
  backgroundColor: {
    type: String,
    default: "white"
  }
});

const copyToClipboard = useClipboardWithMessage();
const handleButtonClick = () => {
  if (!props.isLoading) {
    copyToClipboard(props.text, { stringify: typeof props.text !== "string" });
  }
};
</script>
